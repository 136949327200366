import React, { createContext, useEffect, useState } from 'react'
import useLocalStorage from './../hooks/useLocalStorage'
import i18n from './../locales/i18n'

const LocaleContext = createContext()

function LocaleProvider({children}) {
    const [savedLocale, saveLocale] = useLocalStorage('mundolenovo-lang',"en")

    const [wait, changeWait] = useState(false)


    const [typeTheme, saveTypeTheme] = useState(() => {
    	const theme = localStorage.getItem('@MundoLenovo: theme')
        if(theme){
            return theme
        }else{
            return 'lightTheme'
        }

  	})

    const updateLocale = locale => saveLocale(locale)

    const updateTheme = (theme) => {
        saveTypeTheme(theme)
    } 

    const setWait = bool => {
        changeWait(bool)
    }

    useEffect(() => {
        i18n.changeLanguage(savedLocale)
    }, [savedLocale])

    useEffect(() => {
    	if (typeTheme) {
      		return localStorage.setItem('@MundoLenovo: theme', typeTheme)
    	}
  	}, [typeTheme])

    return (
        <LocaleContext.Provider
            value={{
                savedLocale,
                updateLocale,

                typeTheme,
                updateTheme,

                wait,
                setWait,
            }}
        >
        {children}
        </LocaleContext.Provider>
    )
}

export { LocaleProvider, LocaleContext as default }

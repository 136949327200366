import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'


import { LocaleProvider } from './contexts/Locale'
import store from './store'

import App from './App'


ReactDOM.render(
    <LocaleProvider>
        <Provider store={store}>
            <App />
        </Provider>
    </LocaleProvider>,

    document.getElementById('root')
)


export const isAuthenticated  = () => localStorage.getItem('Sama100Anos-Admin-Token') !== null

export const getToken = () => localStorage.getItem('Sama100Anos-Admin-Token')

export const login = token => {
    localStorage.setItem('Sama100Anos-Admin-Token', token)
}

export const logout = () => {
    localStorage.removeItem('Sama100Anos-Admin-Token')
}

